<template>
  <section>
    <b-card>
      <b-card-title>Pagamentos
        <b-button
          variant="relief-primary"
          class="float-right"
          :disabled="loading"
          @click.stop="checkPayments()"
        >
          <span v-if="!loading">Verificar todos</span>
          <b-spinner
            v-else
            small
          />
        </b-button>
      </b-card-title>

      <TablePagination
        v-model="items"
        url="/admin/payments/paginate"
        :per-page="10"
        :fields="fields"
        default-filter-by="email"
      >
        <template v-slot:name="data">
          {{ data.items.user.personal_data.full_name }}
        </template>

        <template v-slot:amount="data">
          {{ data.items.amount | currency }}
        </template>

        <template v-slot:type="data">
          <b-button
            :variant="getType(data.items.type).color"
            size="sm"
          >
            {{ getType(data.items.type).message }}
          </b-button>
        </template>

        <template v-slot:status="data">
          <b-button
            :variant="getStatus(data.items.status).color"
            size="sm"
          >
            {{ getStatus(data.items.status).message }}
          </b-button>
        </template>

        <template v-slot:actions="data">
          <b-button
            :disabled="loading || data.items.status === 5"
            variant="relief-primary"
            size="sm"
            @click.stop="checkPayment(data.items.id)"
          >
            <span v-if="!loading">Verificar</span>
            <b-spinner
              v-else
              small
            />
          </b-button>
        </template>

        <template v-slot:created_at="data">
          <b-button
            size="sm"
            variant="relief-primary"
          >
            {{ data.items.created_at | date }}
          </b-button>
        </template>
      </TablePagination>
    </b-card>
  </section>
</template>

<script>
import {
  BCard, BCardTitle, BButton, BSpinner
} from 'bootstrap-vue'
import moment from 'moment'
import TablePagination from '@/components/TablePagination/Index.vue'
import currencyPrecision from '@/libs/currencyPrecision'

export default {
  components: {
    BCard,
    BCardTitle,
    BButton,
    BSpinner,
    TablePagination
  },
  filters: {
    currency (val) {
      return currencyPrecision(val, 2, 2)
    },
    date (val) {
      return moment(val).format('DD/MM/Y HH:mm:ss')
    }
  },
  data: () => ({
    loading: false,
    items: [],
    fields: [
      {
        key: 'created_at',
        label: 'Data'
      },
      {
        key: 'name',
        label: 'Nome'
      },
      {
        key: 'amount',
        label: 'Valor'
      },
      {
        key: 'type',
        label: 'Tipo'
      },
      {
        key: 'status',
        label: 'status'
      },
      {
        key: 'actions',
        label: 'Ações'
      }
    ]
  }),
  methods: {
    getStatus (status) {
      const response = {}

      switch (status) {
      case 1:
        response.color = 'relief-primary'
        response.message = 'Pendente'
        break

      case 2:
        response.color = 'relief-warning'
        response.message = 'Em análise'
        break

      case 3:
        response.color = 'relief-danger'
        response.message = 'Cancelado'
        break

      case 4:
        response.color = 'relief-danger'
        response.message = 'Recusado'
        break

      case 5:
        response.color = 'relief-success'
        response.message = 'Pago'
        break

      default:
        response.color = 'relief-danger'
        response.message = 'Não identificado'
      }

      return response
    },
    getType (type) {
      const response = {}

      switch (type) {
      case 1:
        response.color = 'relief-primary'
        response.message = 'Mercado Pago'
        break

      default:
        response.color = 'relief-danger'
        response.message = 'Não identificado'
      }

      return response
    },
    async checkPayment (id) {
      this.loading = true
      const url = `/admin/payments/mercado_pago/check/${id}`

      await this.$http.get(url)
        .then(res => {
          if (res.data === 5) {
            const index = this.items.findIndex(item => item.id === id)
            this.items[index].status = 5
          }
        }).finally(() => {
          this.loading = false
        })
    },
    async checkPayments () {
      this.loading = true
      const url = '/admin/payments/mercado_pago/check_in_bulk'
      const params = {
        list_ids: this.items.map(item => item.id)
      }

      await this.$http.post(url, params)
        // eslint-disable-next-line no-unused-vars
        .then(res => {
          // if (res.data === 5) {
          //   const index = this.items.findIndex(item => item.id === id)
          //   this.items[index].status = 5
          // }
        }).finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
